import { trimNumberToStepValue } from "@/utils/numbers";
import { getSymbolQuoteValue, PriceChangeType } from "@/hooks/trade/tradeSocket";
import { ITradeOrderState } from "@/contexts/TradeOrder/interfaces";
import { ISymbolInfo } from "@/services/trade/symbols";
import { divide, multiply, subtract, sum } from "../mathUtils";
import { formatAmount, normalyzeDecimalLength } from "../format";
import { ITradeOrder } from "@/services/trade/order";
import { SYMBOLS_IMAGES_MAP_STORAGE_KEY } from "@/constants/index";

const getCurrencies = (symbolId): string[] => {
    const source = currencies[symbolId.substring(0, 3).toLowerCase()] || currencies["default"];
    const target = currencies[symbolId.substring(3, 6).toLowerCase()] || currencies["default"];

    return [source, target];
};

const getCryptoCurrencies = (symbolId): string[] => {
    const source = crypto[symbolId.substring(0, 3).toLowerCase()] || crypto["default"];
    const target = currencies[symbolId.substring(3, 6).toLowerCase()] || null;

    return [source, target];
};

const assetNameParser = (name, assetType) => {
    const defaultLink = assetType["default"];
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const month = months.find(m => name.includes(m));

    return (
        assetType[name] ||
        assetType[name.split("-")[0]] ||
        assetType[name.split(month)[0]] ||
        defaultLink
    );
};

const getCommodities = symbolId => {
    const source = assetNameParser(symbolId, commodities);

    return [source, null];
};

const getStocks = symbolId => {
    const source = stocks[symbolId] || stocks["default"];

    return [source, null];
};

const getIndices = symbolId => {
    const source = indices[symbolId.split("-")[0]] || indices["default"];

    return [source, null];
};

const getFavourites = symbolId => {
    const map = {
        stocks: {
            availability: !!stocks[symbolId],
            fulfillment: getStocks(symbolId),
        },
        commodities: {
            availability: !!commodities[symbolId.split("-")[0]],
            fulfillment: getCommodities(symbolId),
        },
        indices: {
            availability: !!indices[symbolId.split("-")[0]],
            fulfillment: getIndices(symbolId),
        },
        currencies: {
            availability: !!currencies[symbolId.substring(0, 3).toLowerCase()],
            fulfillment: getCurrencies(symbolId),
        },
        crypto: {
            availability: !!crypto[symbolId.substring(0, 3).toLowerCase()],
            fulfillment: getCryptoCurrencies(symbolId),
        },
    };

    const favouritesImages = Object.keys(map)
        .map(asset => {
            if (map[asset].availability) {
                return map[asset].fulfillment;
            }

            return null;
        })
        .filter(d => d)[0];

    if (!favouritesImages || !Array.isArray(favouritesImages) || favouritesImages.length < 2) {
        return [null, null];
    }

    return favouritesImages;
};

export let stocks;
export let commodities;
export let indices;
export let currencies;
export let crypto;

interface ISymbolImagesMap {
    stocks?: Record<string, string>;
    commodities?: Record<string, string>;
    indices?: Record<string, string>;
    currencies?: Record<string, string>;
    crypto?: Record<string, string>;
}

let symbolImagesParsed = false;

const parseSymbolImages = () => {
    if (symbolImagesParsed) {
        return;
    }

    const symbolImagesMapString = localStorage.getItem(SYMBOLS_IMAGES_MAP_STORAGE_KEY);

    let symbolImagesMap: ISymbolImagesMap = {};

    if (symbolImagesMapString) {
        try {
            symbolImagesMap = JSON.parse(symbolImagesMapString);
        } catch (e) {
            console.error("Received invalid symbol images map");
        }

        if (
            ["stocks", "commodities", "indices", "currencies", "crypto"].every(group => {
                return symbolImagesMap[group];
            })
        ) {
            symbolImagesParsed = true;
        }
    }

    stocks = symbolImagesMap.stocks || {};
    commodities = symbolImagesMap.commodities || {};
    indices = symbolImagesMap.indices || {};
    currencies = symbolImagesMap.currencies || {};
    crypto = symbolImagesMap.crypto || {};
};

export const getSymbolAssets = (symbolId: string, group: string): string[] => {
    parseSymbolImages();

    let selectedImages;

    switch (group) {
        case "Currencies": {
            selectedImages = getCurrencies(symbolId);
            break;
        }

        case "Forex": {
            selectedImages = getCurrencies(symbolId);
            break;
        }

        case "Cryptocurrencies": {
            selectedImages = getCryptoCurrencies(symbolId);
            break;
        }

        case "Commodities": {
            selectedImages = getCommodities(symbolId);
            break;
        }

        case "Stocks": {
            selectedImages = getStocks(symbolId);
            break;
        }

        case "Indices": {
            selectedImages = getIndices(symbolId);
            break;
        }

        case "Favourites": {
            selectedImages = getFavourites(symbolId);
            break;
        }

        default:
            break;
    }

    if (!selectedImages) {
        selectedImages = [currencies["default"]];
    }

    const domain = window.location.hostname;

    if (domain === "localhost") {
        const baseURL = generateWebtraderCDN() + "/symbols/";

        selectedImages = selectedImages.map(s => {
            return s ? `${baseURL}${s}` : null;
        });
    } else {
        const baseURL = generateWebtraderCDN() + "/symbols/";

        selectedImages = selectedImages.map(s => {
            return s ? `${baseURL}${s}` : null;
        });
    }

    let [assetImage1, assetImage2] = selectedImages;

    if (assetImage1 && !assetImage1.includes("http")) {
        assetImage1 = `/images/symbols/${assetImage1}`;
    }

    if (assetImage2 && !assetImage2.includes("http")) {
        assetImage2 = `/images/symbols/${assetImage2}`;
    }

    return [assetImage1, assetImage2];
};

function generateWebtraderCDN() {
    const array = window.location.hostname.split(".");
    array.shift();
    let host = array.join(".") === "" ? "patronfx.com" : array.join(".");
    if (host.includes("adesk-service")) {
        host = "patronfx.com";
    }
    const baseURL = `https://webtrader-cdn.${host}`;

    return baseURL;
}

function url_domain(data) {
    const a = document.createElement("a");
    a.href = data;
    return a.hostname;
}

export const getPrice = (ask: number, bid: number): number => {
    if (!ask || !bid) {
        return null;
    }

    return (ask + bid) / 2;
};

export const getCurrentPrice = (
    order: Partial<ITradeOrder>,
    symbolInfo: ISymbolInfo,
    ask: number,
    bid: number,
    isPendingOrder?: boolean
): string => {
    const isBuy = order?.type.toLowerCase().includes("buy");

    const marketPrice = isPendingOrder ? (isBuy ? ask : bid) : isBuy ? bid : ask;

    let currentPriceValue = getFormattedPrice(marketPrice, symbolInfo?.point);

    if (!currentPriceValue || currentPriceValue === "---") {
        currentPriceValue = order?.closePrice.toString();
    }

    const currentPrice = normalyzeDecimalLength(
        formatAmount(Number(currentPriceValue), "", symbolInfo?.digits),
        symbolInfo?.digits
    );

    return currentPrice;
};

export const getFormattedPrice = (price: number, point: number): string => {
    if (price === null) {
        return "---";
    }

    return trimNumberToStepValue(price, point);
};

type SLTPPriceType = "StopLoss" | "TakeProfit";

export type GetSLTPPriceFromPipsStateFields = Pick<
    ITradeOrderState,
    | "activeOperationCategory"
    | "activeSymbolId"
    | "activeSymbolPoint"
    | "isPendingOrder"
    | "pendingOrderPrice"
    | "isForex"
>;

export const getSLTPPriceFromPips = (
    pipsOrPoints: number,
    state: GetSLTPPriceFromPipsStateFields,
    priceType: SLTPPriceType,
    fixedPrice = null
): number => {
    if (pipsOrPoints === null) {
        return null;
    }

    const {
        activeOperationCategory,
        activeSymbolId,
        activeSymbolPoint,
        isPendingOrder,
        pendingOrderPrice,
        isForex,
    } = state;
    const { ask, bid } = getSymbolQuoteValue(activeSymbolId);

    const price = fixedPrice
        ? fixedPrice
        : isPendingOrder
        ? pendingOrderPrice
        : activeOperationCategory === "Buy"
        ? ask
        : bid;

    let sign = 1;

    if (
        (priceType === "StopLoss" && activeOperationCategory === "Buy") ||
        (priceType === "TakeProfit" && activeOperationCategory === "Sell")
    ) {
        sign = -1;
    }
    return sum(price, sign * pipsOrPoints * (isForex ? 10 : 1) * activeSymbolPoint);
};

/**
 * Wrapper methods for getSLTPPriceFromPips
 */
export const getStopLossPriceFromPips = (
    pipsOrPoints: number,
    state: GetSLTPPriceFromPipsStateFields,
    fixedPrice = null
): number => {
    return getSLTPPriceFromPips(pipsOrPoints, state, "StopLoss", fixedPrice);
};

export const getTakeProfitPriceFromPips = (
    pipsOrPoints: number,
    state: GetSLTPPriceFromPipsStateFields,
    fixedPrice = null
): number => {
    return getSLTPPriceFromPips(pipsOrPoints, state, "TakeProfit", fixedPrice);
};

export const getSpreadInPoints = (ask: number, bid: number, digits: number): number =>
    multiply(subtract(ask, bid), Math.pow(10, digits));

export const getSpreadInPips = (ask: number, bid: number, digits: number): number =>
    divide(getSpreadInPoints(ask, bid, digits), 10);

export const getAssetsPriceChangePercentage = (bid: number, referencePrice: number): number | null => {
    if (bid === null || referencePrice === null) {
        return null;
    }

    const diff = bid - referencePrice;

    return (diff / referencePrice) * 100;
};

export const getAssetsPriceChangeType = (bid?: number, referencePrice?: number): PriceChangeType => {
    let change = null;
    let changeType = PriceChangeType.None;

    if (bid !== null && referencePrice !== null) {
        const diff = bid - referencePrice;

        change = (diff / referencePrice) * 100;

        if (change > 0) {
            changeType = PriceChangeType.Up;
        } else if (change < 0) {
            changeType = PriceChangeType.Down;
        }
    }

    return changeType;
};

interface ISentimentsInfo {
    buy: number;
    sell: number;
}

export const getSentimentsInfo = (symbolInfo: ISymbolInfo): ISentimentsInfo => {
    if (!symbolInfo.sentiments || !symbolInfo.sentiments.bull || !symbolInfo.sentiments.bear) {
        return {
            buy: 50,
            sell: 50,
        };
    }

    const buyCount = Number(symbolInfo.sentiments.bull);
    const sellCount = Number(symbolInfo.sentiments.bear);
    const total = buyCount + sellCount;
    const buy = (buyCount * 100) / total;
    const sell = 100 - buy;

    return { buy, sell };
};

export const tradeModeFilter = (symbolInfo: ISymbolInfo): boolean => {
    const tradeMode = symbolInfo?.ex?.tradeMode;
    if (!tradeMode) {
        return true;
    }

    return tradeMode === "Full";
};

export const closeModeFilter = (symbolInfo: ISymbolInfo): boolean =>
    symbolInfo?.ex?.tradeMode !== "Close";

export const hiddenAssetsFilter = (symbolInfo: ISymbolInfo): boolean => !symbolInfo?.isHidden;

interface ISymbolFilterParams {
    search: string;
    categoriesNames: string[];
    activeCategory: string;
    favourites: string[];
}

export const categoryFilter = (symbolInfo: ISymbolInfo, params: ISymbolFilterParams): boolean => {
    const { search, categoriesNames, activeCategory, favourites } = params;

    if (search) {
        return true;
    } else if (!activeCategory) {
        return false;
    } else if (activeCategory === "Favourites") {
        if (!categoriesNames.includes(symbolInfo.group.name)) {
            return false;
        }

        return favourites?.includes(symbolInfo.id);
    }

    return activeCategory === symbolInfo.group.name;
};

const searchFilter = (symbolInfo: ISymbolInfo, search: string) => {
    const needle = search.trim().toLowerCase();

    return !search || (symbolInfo.ex?.displayName || symbolInfo.id)?.toLowerCase().includes(needle);
};

export const rolloverDateFilter = (symbolInfo: ISymbolInfo): boolean => {
    const {
        ex: { rolloverDate: rolloverDateString },
    } = symbolInfo;

    if (!rolloverDateString) {
        return true;
    }

    const now = new Date();
    const rolloverDate = new Date(rolloverDateString);

    rolloverDate.setDate(rolloverDate.getDate() - 7); // week before the rollover date

    return now < rolloverDate;
};

// FILTERING SYMBOLS
export const applySymbolFilters = (
    symbolsInfo: ISymbolInfo[],
    params: ISymbolFilterParams
): ISymbolInfo[] => {
    return symbolsInfo.reduce((acc, symbolInfo) => {
        if (
            !hiddenAssetsFilter(symbolInfo) ||
            !categoryFilter(symbolInfo, params) ||
            !searchFilter(symbolInfo, params.search)
            // !tradeModeFilter(symbolInfo) ||
            // !closeModeFilter(symbolInfo) ||
        ) {
            return acc;
        }

        acc.push(symbolInfo);

        return acc;
    }, []);
};

export const getLeverageDisplayValue = (symbolInfo: ISymbolInfo, accountLeverage: number): number => {
    const percentage = symbolInfo?.percentage;
    const marginCalculationMode = symbolInfo?.ex?.marginCalculationMode;

    if (marginCalculationMode === "CFD") {
        return Math.round((1 / percentage) * 100);
    }

    return Math.round((accountLeverage / percentage) * 100);
};

export const getDefaultAssetsSort = (tradeData: ISymbolInfo[]): ISymbolInfo[] => {
    // default sorting works only for currencies and stocks categories, there are no requirements for other
    switch (tradeData[0]?.group?.name) {
        case "Currencies": {
            const fav = [];
            let modifiedTradeData = [];

            tradeData.forEach(item => {
                if (item.id === "GBPUSD" || item.id === "EURUSD") {
                    fav.push(item);
                } else {
                    modifiedTradeData.push(item);
                }
            });

            if (fav.length) {
                if (fav[0].id === "EURUSD") {
                    modifiedTradeData = [...fav, ...modifiedTradeData];
                } else {
                    modifiedTradeData = [...fav.reverse(), ...modifiedTradeData];
                }
                return modifiedTradeData;
            }
            return tradeData;
        }
        case "Stocks":
            return tradeData.sort((a, b) =>
                `${a.ex.displayName}`.toLowerCase().localeCompare(`${b.ex.displayName}`.toLowerCase())
            );
        default:
            return tradeData;
    }
};

export const getSymbolNameTranslation = (
    symbol: ISymbolInfo,
    t: (string) => string,
    separator = "vs"
): string => {
    if (!symbol) {
        return "";
    }

    if (symbol.group.name === "Currencies" && t) {
        const currency1 = symbol.id.slice(0, 3);
        const currency2 = symbol.id.slice(3, 6);

        return `${t(currency1)} ${separator} ${t(currency2)}`;
    }
    let displayData = symbol.ex.displayName || symbol.id;
    // exception specificly for this asset
    if (symbol.ex.displayName == "AMD") {
        displayData = symbol.ex.description;
    }

    return `${t(displayData)} ${separator} ${t(symbol.currency)}`;
};
